<template>
  <div class="fragment">

<!--    <SearchEngine/>-->


    <div class="content-top-line content-top-line--with-bottom-line">
      <div class="content-tabs content-tabs--separator">
<!--        <div class="content-tabs__item " v-bind:class="{active: tab === 1}" @click="changeTab(1)">-->
<!--          <div class="content-tabs__ico">-->
<!--           -->
<!--          </div>-->
<!--        </div>-->
      </div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter" />
      </div>
    </div>

    <div class="light-bg-block">
      <div class="btn-right-block">
        <div class="d-flex align-items-center mr-2" style="position: relative;"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate([
          'common_import',
        ])"></span>
          <TableGeadButton
              :value="$t('common_import.localization_value.value')"
              :ico="'import'"
              @click.native="isModalImportPopup = true"
          />
        </div>
<!--          <MainButton class="btn-fit-content ml-3"-->
<!--                    :value="$t('common_AddNew.localization_value.value')"-->
<!--                    :ico="'ico'"-->
<!--                    @click.native="changePromoCodePopup(true)"-->
<!--        >-->
<!--          <template slot="ico">-->
<!--            <PlusIconSVG class="btn-icon-plus"/>-->
<!--          </template>-->
<!--        </MainButton>-->
      </div>
    </div>

    <PromoCodePopup
            v-if="isModalPromoCodePopup"
            :type="'create'"
            @reload="$emit('reload')"
            @close="changePromoCodePopup(false)"
    />

    <PromoCodeImportPopup
        v-if="isModalImportPopup"
        @close="isModalImportPopup = false"
        @reload="$emit('reload')"
    />

  </div>
</template>



<script>
  import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
  // import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  // import PlusIconSVG from '../../../../../../../../public/img/UI-group/plus-icon.svg?inline'
  // import SearchEngine from "../../../../../../coreComponents/SearchEngine/SearchEngine";
  import PromoCodePopup from "../../../../popups/PromoCodePopup/PromoCodePopup";
  import TableGeadButton from "@/components/coreComponents/TableComponents/TableHeadButton/TableHeadButton";
  import PromoCodeImportPopup
    from "@/components/modules/PromoCodeModule/popups/PromoCodeImportPopup/PromoCodeImportPopup";
  // import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";


  export default {
    name: "PromoCodeHead",
    components: {
      PromoCodeImportPopup,
      TableGeadButton,
      PromoCodePopup,
      ToggleFilterButton,
      // MainButton,
      // PlusIconSVG,
      // DefaultCheckbox,
      // SearchEngine,
    },

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
    },

    data(){
      return{
        isModalPromoCodePopup: false,
        isModalImportPopup: false,
      }
    },

    methods: {

      changePromoCodePopup(val, id = false) {
        if(id){
          this.orderId = id
        }
        this.isModalPromoCodePopup = val

        if(!val){
          this.orderId = -1
        }
      },
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../../../../scss/mixins/mixins";


</style>

